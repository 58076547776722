import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Trophy, Star, Timer, Heart, Twitter } from 'lucide-react';

const ChristmasGame = ({ isLightMode }) => {
  const [cards, setCards] = useState([]);
  const [flippedCards, setFlippedCards] = useState([]);
  const [foundGoodCard, setFoundGoodCard] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [score, setScore] = useState(1000);

  useEffect(() => {
    const createCards = () => {
      // Create 11 regular cards
      const cards = Array.from({ length: 11 }, (_, i) => ({
        id: i + 1,
        image: `/${i + 1}.png`,
        isGood: false,
        uniqueId: Math.random()
      }));

      // Add the special card
      cards.push({
        id: 12,
        image: '/good.png',
        isGood: true,
        uniqueId: Math.random()
      });

      return cards.sort(() => Math.random() - 0.5);
    };

    setCards(createCards());
  }, []);

  useEffect(() => {
    if (gameStarted && !foundGoodCard) {
      const timer = setInterval(() => {
        setScore(prev => Math.max(0, prev - 1));
      }, 100);
      return () => clearInterval(timer);
    }
  }, [gameStarted, foundGoodCard]);

  const handleCardClick = (clickedCard) => {
    if (!gameStarted) setGameStarted(true);
    if (flippedCards.includes(clickedCard.uniqueId) || foundGoodCard) return;

    setAttempts(prev => prev + 1);
    setFlippedCards(prev => [...prev, clickedCard.uniqueId]);

    if (clickedCard.isGood) {
      handleWin();
    } else {
      setTimeout(() => {
        setFlippedCards(prev => prev.filter(id => id !== clickedCard.uniqueId));
      }, 1000);
    }
  };

  const handleWin = async () => {
    setFoundGoodCard(true);
    const finalScore = Math.max(0, score - (attempts * 50));
    setScore(finalScore);
    
    const winSound = new Audio('/new.mp3');
    await winSound.play().catch(console.error);
    setShowCongrats(true);
  };

  const handleShareTwitter = () => {
    const tweetText = encodeURIComponent(
      `🎮 I scored ${score} points in the KOSWOG Christmas Game! Share your score with #koswog to be eligible for the KOSWOG NFT in 2025! 🎁\n\nPlay now at https://koswog.com`
    );
    window.open(`https://twitter.com/intent/tweet?text=${tweetText}`, '_blank');
  };

  const resetGame = () => {
    setCards(prev => [...prev].sort(() => Math.random() - 0.5));
    setFlippedCards([]);
    setFoundGoodCard(false);
    setShowCongrats(false);
    setAttempts(0);
    setScore(1000);
    setGameStarted(false);
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center pt-4 pb-8">
      {/* Score Panel */}
      <div className={`mb-6 px-6 py-3 rounded-xl ${
        isLightMode ? 'bg-white/40' : 'bg-white/5'
      } backdrop-blur-sm border ${
        isLightMode ? 'border-slate-200/50' : 'border-white/10'
      } flex items-center gap-8`}>
        <div className="flex items-center gap-2">
          <Heart className={`w-5 h-5 ${
            isLightMode ? 'text-red-500' : 'text-red-400'
          }`} />
          <span className={`font-medium ${
            isLightMode ? 'text-slate-700' : 'text-white'
          }`}>
            Attempts: {attempts}
          </span>
        </div>
        <div className="flex items-center gap-2">
          <Timer className={`w-5 h-5 ${
            isLightMode ? 'text-blue-500' : 'text-blue-400'
          }`} />
          <span className={`font-medium ${
            isLightMode ? 'text-slate-700' : 'text-white'
          }`}>
            Score: {score}
          </span>
        </div>
      </div>

      {/* Game Grid */}
      <div className="grid grid-cols-4 gap-4 w-full max-w-2xl px-4">
        {cards.map(card => (
          <motion.div
            key={card.uniqueId}
            className="relative aspect-square rounded-xl cursor-pointer overflow-hidden"
            whileHover={{ scale: 1.02 }}
            onClick={() => handleCardClick(card)}
          >
            <div className="absolute inset-0 w-full h-full" style={{ perspective: '1000px' }}>
              <motion.div
                className="relative w-full h-full"
                initial={{ rotateY: 180 }}
                animate={{ 
                  rotateY: flippedCards.includes(card.uniqueId) ? 0 : 180 
                }}
                transition={{ duration: 0.6 }}
                style={{ transformStyle: 'preserve-3d' }}
              >
                {/* Front Side (Card Image) */}
                <div 
                  className="absolute inset-0 w-full h-full rounded-xl overflow-hidden"
                  style={{ backfaceVisibility: 'hidden' }}
                >
                  <img
                    src={card.image}
                    alt="Card"
                    className="w-full h-full object-cover"
                  />
                </div>

                {/* Back Side (Star) */}
                <div 
                  className={`absolute inset-0 w-full h-full rounded-xl ${
                    isLightMode 
                      ? 'bg-gradient-to-br from-blue-100 to-purple-100 border-slate-200/50' 
                      : 'bg-gradient-to-br from-[#1a1c30] to-[#2a2d4a] border-white/10'
                  } border backdrop-blur-sm flex items-center justify-center`}
                  style={{ 
                    backfaceVisibility: 'hidden',
                    transform: 'rotateY(180deg)'
                  }}
                >
                  <Star className={`w-10 h-10 ${
                    isLightMode ? 'text-slate-400' : 'text-white/30'
                  }`} />
                </div>
              </motion.div>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Win Effects */}
      <AnimatePresence>
        {foundGoodCard && (
          <motion.div
            className="fixed inset-0 pointer-events-none"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {Array.from({ length: 50 }).map((_, i) => (
              <motion.div
                key={i}
                className="absolute"
                initial={{
                  top: '50%',
                  left: '50%',
                  scale: 0,
                }}
                animate={{
                  top: Math.random() * 100 + '%',
                  left: Math.random() * 100 + '%',
                  scale: Math.random() * 2 + 1,
                  opacity: [1, 0],
                }}
                transition={{
                  duration: 2,
                  delay: i * 0.02,
                  ease: 'easeOut',
                }}
              >
                <Star className="w-6 h-6 text-yellow-400" />
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      {/* Win Modal */}
      <AnimatePresence>
        {showCongrats && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center z-50 bg-black/50 backdrop-blur-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className={`p-8 rounded-2xl ${
                isLightMode ? 'bg-white/90' : 'bg-[#1a1c30]/90'
              } backdrop-blur-xl border ${
                isLightMode ? 'border-slate-200' : 'border-white/10'
              } shadow-2xl max-w-md w-full mx-4`}
              initial={{ scale: 0.8, y: 20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.8, y: 20 }}
            >
              <div className="relative">
                <Trophy className="w-16 h-16 text-yellow-400 mx-auto mb-4" />
                <motion.div
                  className="absolute inset-0"
                  animate={{
                    scale: [1, 1.2, 1],
                    opacity: [0.5, 1, 0.5],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                  }}
                >
                  <Trophy className="w-16 h-16 text-yellow-400 mx-auto mb-4" />
                </motion.div>
              </div>

              <h2 className={`text-2xl font-bold text-center mb-4 ${
                isLightMode ? 'text-slate-800' : 'text-white'
              }`}>
                Congratulations!
              </h2>

              <div className={`space-y-2 text-center mb-6 ${
                isLightMode ? 'text-slate-600' : 'text-white/80'
              }`}>
                <p className="font-medium text-xl">Score: {score}</p>
                <p>Attempts: {attempts}</p>
                <p className="text-sm italic">
                  Share your score on Twitter with #koswog to be eligible for the KOSWOG NFT in 2025!
                </p>
              </div>

              <div className="space-y-3">
                <motion.button
                  className="w-full px-6 py-3 rounded-xl bg-[#1DA1F2] text-white font-medium hover:bg-[#1a8cd8] transition-colors flex items-center justify-center gap-2"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleShareTwitter}
                >
                  <Twitter size={20} />
                  Share on Twitter
                </motion.button>

                <motion.button
                  className="w-full px-6 py-3 rounded-xl bg-[#3a6df0] text-white font-medium hover:bg-[#3461d1] transition-colors"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={resetGame}
                >
                  Play Again
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ChristmasGame;