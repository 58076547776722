import React from 'react';
import { motion } from 'framer-motion';

const AnimatedLinkCard = ({ app, categories, isLightMode, setSelectedCategory }) => {
  // Sadece alt kategoriyi bul
  const category = categories.find(c => 
    c._id === (typeof app.category === 'object' ? app.category._id : app.category)
  );
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      onMouseEnter={() => window.playSound?.linkHover()}
      className="mb-3"
    >
      <motion.div 
        className="relative group"
        whileHover={{ scale: 1.02, y: -2 }}
        onHoverStart={() => window.playSound?.linkHover()}
        transition={{ type: "spring", stiffness: 400, damping: 15 }}
      >
        {/* Background Image */}
        {app?.backgroundImage && (
          <div 
            className="absolute inset-0 rounded-2xl overflow-hidden transition-opacity duration-300"
            style={{
              background: `url(${app.backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              opacity: 1
            }}
          />
        )}

        {/* Overlay */}
        <div className={`absolute inset-0 rounded-2xl bg-gradient-to-r opacity-0 group-hover:opacity-100 transition-all duration-300 backdrop-blur-[1px]
          ${isLightMode 
            ? 'from-white/80 via-white/60 to-transparent' 
            : 'from-[#1a1c30]/80 via-[#1a1c30]/60 to-transparent'
          }`} 
        />

        {/* Main Content */}
        <div className={`relative z-10 flex items-center justify-between p-4 rounded-2xl transition-all duration-300 backdrop-blur-[1px]
          ${isLightMode 
            ? 'border border-gray-200 group-hover:border-gray-300 bg-white/60' 
            : 'border border-white/5 group-hover:border-white/20'
          }`}>
          
          {/* Left Section */}
          <div className="flex items-center gap-4">
            {/* App Logo */}
            {app?.logo && (
              <motion.a 
                href={app.link} 
                target="_blank"
                rel="noopener noreferrer"
                className="w-9 h-9 rounded-lg overflow-hidden shadow-lg"
                whileHover={{ scale: 1.15 }}
                onMouseEnter={() => window.playSound?.linkHover()}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
              >
                <img 
                  src={app.logo} 
                  alt={app.name}
                  className="w-full h-full object-cover"
                />
              </motion.a>
            )}

            <div className="flex items-center gap-3">
              {/* App Name */}
              <span className={`font-medium text-[15px] ${
                isLightMode ? 'text-gray-800' : 'text-white'
              }`}>
                {app?.name}
              </span>
              
              {/* Category Button */}
              {category && (
                <button
                  onClick={() => {
                    window.playSound?.click();
                    setSelectedCategory(category._id);
                  }}
                  onMouseEnter={() => window.playSound?.categoryHover()}
                  className="px-3 py-1 rounded-lg text-xs font-medium transition-all duration-200
                    bg-white/5 hover:bg-white/10 text-white/80 hover:text-white
                    border border-white/10 hover:border-white/20
                    backdrop-blur-sm shadow-sm"
                >
                  {category.name}
                </button>
              )}
            </div>
          </div>

          {/* Right Section */}
          <div className="flex items-center gap-5">
            {/* Additional Buttons */}
            {app?.additionalButtons?.map((button, index) => (
              <motion.a
                key={index}
                href={button.link}
                target="_blank"
                rel="noopener noreferrer"
                className="w-7 h-7 rounded-lg overflow-hidden shadow-md"
                whileHover={{ scale: 1.15 }}
                onMouseEnter={() => window.playSound?.linkHover()}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
              >
                <img 
                  src={button.logo}
                  alt={`Additional button ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </motion.a>
            ))}

            {/* Website Status */}
            <div className="flex items-center gap-2">
              <span className={`w-1.5 h-1.5 rounded-full ${
                app?.website?.status 
                  ? 'bg-green-500 shadow-lg shadow-green-500/30' 
                  : isLightMode ? 'bg-gray-300' : 'bg-white/30'
              }`} />
              <span className={isLightMode ? 'text-gray-600 text-sm' : 'text-white/70 text-sm'}>
                {app?.website?.status ? 'Website Online' : 'Website Offline'}
              </span>
            </div>
            
            {/* Open Button */}
            <motion.a 
              href={app?.website?.url} 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                window.playSound?.open();
                setTimeout(() => {
                  window.open(app?.website?.url, '_blank');
                }, 200);
              }}
              onMouseEnter={() => window.playSound?.linkHover()}
              className="px-5 py-2 rounded-xl bg-[#3a6df0] text-white text-sm font-medium hover:bg-[#3461d1] transition-colors shadow-lg shadow-[#3a6df0]/20"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
            >
              Open
            </motion.a>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default AnimatedLinkCard;