import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const ChristmasSnow = ({ isLightMode }) => {
  const [snowflakes, setSnowflakes] = useState([]);
  
  useEffect(() => {
    const createSnowflake = () => {
      const size = Math.random() * 3 + 1;
      return {
        id: Math.random(),
        size,
        initialLeft: Math.random() * 100,
        swayAmount: Math.random() * 30 + 10,
        duration: Math.random() * 5 + 8,
        delay: -Math.random() * 10,
        opacity: Math.random() * 0.5 + 0.2
      };
    };

    setSnowflakes(Array.from({ length: 150 }, createSnowflake));

    const interval = setInterval(() => {
      setSnowflakes(prev => {
        const filtered = prev.slice(1);
        return [...filtered, createSnowflake()];
      });
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fixed inset-0 pointer-events-none" style={{ zIndex: 1 }}>
      {snowflakes.map((snowflake) => (
        <motion.div
          key={snowflake.id}
          className="absolute rounded-full"
          style={{
            width: snowflake.size,
            height: snowflake.size,
            left: `${snowflake.initialLeft}%`,
            background: isLightMode ? 
              'radial-gradient(circle at 30% 30%, rgba(255,255,255,1), rgba(255,255,255,0.8))' : 
              'radial-gradient(circle at 30% 30%, rgba(255,255,255,0.9), rgba(255,255,255,0.7))',
            boxShadow: isLightMode ? 
              '0 0 2px rgba(255,255,255,0.8)' : 
              '0 0 2px rgba(255,255,255,0.5)'
          }}
          initial={{ 
            y: -20,
            x: 0,
            opacity: 0,
          }}
          animate={{
            y: ['0vh', '105vh'],
            x: [`${-snowflake.swayAmount}px`, `${snowflake.swayAmount}px`, `${-snowflake.swayAmount}px`],
            opacity: [0, snowflake.opacity, snowflake.opacity, 0],
          }}
          transition={{
            y: {
              duration: snowflake.duration,
              repeat: Infinity,
              ease: "linear",
              delay: snowflake.delay,
            },
            x: {
              duration: snowflake.duration * 0.5,
              repeat: Infinity,
              ease: "easeInOut",
              repeatType: "reverse",
              delay: snowflake.delay,
            },
            opacity: {
              duration: snowflake.duration,
              repeat: Infinity,
              ease: "linear",
              delay: snowflake.delay,
            }
          }}
        />
      ))}
    </div>
  );
};

export default ChristmasSnow;