import React, { useState, useEffect } from 'react';
import SidebarCategories from './components/SidebarCategories';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import Sound from './components/Sound';
import AnimatedLinkCard from './components/AnimatedLinkCard';
import ChristmasSnow from './components/ChristmasSnow';
import ChristmasGame from './components/ChristmasGame';
import {
  Bell,
  Layout,
  Search,
  User,
  Sun,
  Moon,
  Volume2,
  VolumeX
} from 'lucide-react';
import LoginModal from './components/LoginModal';
import AdminPanel from './components/AdminPanel';

// BackgroundVideo component
const BackgroundVideo = ({ isLightMode }) => {
  const videoRef = React.useRef(null);

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.log("Video autoplay failed:", error);
      });
    }
  }, []);

  return (
    <div className="fixed inset-0" style={{ zIndex: 0 }}>
      <video 
        ref={videoRef}
        autoPlay 
        loop 
        muted 
        playsInline
        className="w-full h-full object-cover"
      >
        <source src="/video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

const App = () => {
  // States
  const [isLightMode, setIsLightMode] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isAdminPanelOpen, setIsAdminPanelOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [apps, setApps] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [hierarchicalCategories, setHierarchicalCategories] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [currentSection, setCurrentSection] = useState('sites'); // 'sites', 'market', or 'christmas'
  const [isMuted, setIsMuted] = useState(() => {
    const saved = localStorage.getItem('isMuted');
    return saved ? JSON.parse(saved) : false;
  });

  // Effects
  useEffect(() => {
    localStorage.setItem('isMuted', JSON.stringify(isMuted));
  }, [isMuted]);

  useEffect(() => {
    const root = document.documentElement;
    if (isLightMode) {
      root.classList.add('light-mode');
    } else {
      root.classList.remove('light-mode');
    }
  }, [isLightMode]);

  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL || 'https://koswog.com/api';

    const fetchData = async () => {
      try {
        const [categoriesRes, hierarchicalRes, appsRes] = await Promise.all([
          axios.get(`${API_URL}/categories`),
          axios.get(`${API_URL}/categories/hierarchical`),
          axios.get(`${API_URL}/apps`)
        ]);

        setCategories(categoriesRes.data);
        setHierarchicalCategories(hierarchicalRes.data);
        setApps(appsRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [isAdminPanelOpen]);

  // Handlers
  const handleLogin = () => {
    setIsLoggedIn(true);
    setIsLoginModalOpen(false);
    setIsAdminPanelOpen(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setIsAdminPanelOpen(false);
  };

  // Filter apps
  const filteredApps = apps.filter(app => {
    const matchesSearch = app.name.toLowerCase().includes(searchTerm.toLowerCase());
    
    let matchesCategory = !selectedCategory;
    
    if (selectedCategory) {
      const selectedCat = categories.find(c => c._id === selectedCategory);
      
      if (selectedCat) {
        if (selectedCat.isParent) {
          const childCategories = categories.filter(c => 
            c.parent && (
              (typeof c.parent === 'object' && c.parent._id === selectedCategory) ||
              (typeof c.parent === 'string' && c.parent === selectedCategory)
            )
          );
          
          const childCategoryIds = childCategories.map(c => c._id);
          const appCategoryId = typeof app.category === 'object' ? app.category._id : app.category;
          matchesCategory = childCategoryIds.includes(appCategoryId);
        } else {
          const appCategoryId = typeof app.category === 'object' ? app.category._id : app.category;
          matchesCategory = appCategoryId === selectedCategory;
        }
      }
    }
  
    return matchesSearch && matchesCategory;
  });

  return (
    <div className={`min-h-screen w-full p-4 flex items-center justify-center transition-colors duration-200
      ${isLightMode 
        ? 'bg-gradient-to-br from-slate-50/30 via-blue-50/20 to-indigo-50/30' 
        : 'bg-gradient-to-br from-blue-500/20 via-purple-500/20 to-red-500/20'}`}>
      
      <BackgroundVideo isLightMode={isLightMode} />
      <ChristmasSnow isLightMode={isLightMode} />
      <Sound isMuted={isMuted} />

      <LoginModal 
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onLogin={handleLogin}
        isLightMode={isLightMode}
      />

      <AdminPanel 
        isOpen={isAdminPanelOpen}
        onClose={() => setIsAdminPanelOpen(false)}
        onLogout={handleLogout}
        isLightMode={isLightMode}
      />
      
      <div className={`w-full max-w-[1250px] h-[90vh] max-h-[860px] rounded-xl backdrop-blur-xl 
        ${isLightMode 
          ? 'bg-white/40 border-slate-200/50 shadow-lg shadow-slate-200/20' 
          : 'bg-[#10121b]/60 border-white/10'
        }
        flex flex-col overflow-hidden relative border transition-colors duration-200`}>
        
        {/* Header */}
        <header className={`h-[58px] px-6 flex items-center border-b transition-colors duration-200
          ${isLightMode 
            ? 'border-slate-200/50 bg-white/20' 
            : 'border-white/10 bg-black/10'
          }`}>
          <div className="flex gap-2 mr-8">
            <div className="w-3 h-3 rounded-full bg-[#f96057] hover:opacity-80 cursor-pointer transition-opacity"></div>
            <div className="w-3 h-3 rounded-full bg-[#f8ce52] hover:opacity-80 cursor-pointer transition-opacity"></div>
            <div className="w-3 h-3 rounded-full bg-[#5fcf65] hover:opacity-80 cursor-pointer transition-opacity"></div>
          </div>

          <nav className="flex items-center">
            <button
              onClick={() => setCurrentSection('sites')}
              className={`px-6 py-5 transition-colors border-b-2 ${
                currentSection === 'sites'
                  ? isLightMode 
                    ? 'text-slate-700 border-slate-700' 
                    : 'text-white/90 border-white/90'
                  : isLightMode
                    ? 'text-slate-500 border-transparent'
                    : 'text-white/60 border-transparent'
              }`}
            >
              Sites
            </button>
            <button
              onClick={() => setCurrentSection('market')}
              className={`px-6 py-5 relative transition-colors ${
                currentSection === 'market'
                  ? isLightMode 
                    ? 'text-slate-700' 
                    : 'text-white/90'
                  : isLightMode
                    ? 'text-slate-500'
                    : 'text-white/60'
              }`}
            >
              Market
              <span className="absolute right-4 top-4 w-1.5 h-1.5 rounded-full bg-[#3a6df0]"></span>
            </button>
            <button
              onClick={() => setCurrentSection('christmas')}
              className={`px-6 py-5 relative transition-colors ${
                currentSection === 'christmas'
                  ? isLightMode 
                    ? 'text-slate-700' 
                    : 'text-white/90'
                  : isLightMode
                    ? 'text-slate-500'
                    : 'text-white/60'
              }`}
            >
              Christmas
              <motion.span
                className="absolute right-4 top-4 w-1.5 h-1.5 rounded-full bg-red-500"
                animate={{
                  scale: [1, 1.2, 1],
                  opacity: [1, 0.8, 1]
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity
                }}
              />
            </button>
          </nav>

          <div className="flex-1 max-w-[400px] mx-auto">
            <div className="relative">
              <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 ${
                isLightMode ? 'text-slate-400' : 'text-white/40'
              }`} />
              <input 
                type="text"
                placeholder="Search apps..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={`w-full h-10 rounded-lg pl-10 pr-4 border transition-colors
                  ${isLightMode 
                    ? 'bg-white/30 text-slate-700 border-slate-200/50 placeholder-slate-400 focus:border-slate-300' 
                    : 'bg-black/20 text-white border-white/10 placeholder-white/40 focus:border-white/20'
                  }`}
              />
            </div>
          </div>

          <div className="flex items-center ml-auto gap-4">
            <button
              onClick={() => {
                window.playSound?.notification();
                setShowNotifications(!showNotifications);
              }}
              className="relative"
            >
              <Bell className={`w-6 h-6 cursor-pointer transition-colors ${
                isLightMode 
                  ? 'text-slate-500 hover:text-slate-700' 
                  : 'text-white/80 hover:text-white'
              }`} />
              {notifications.length > 0 && (
                <span className="absolute -top-1 -right-1 w-5 h-5 bg-[#3a6df0] rounded-full flex items-center justify-center text-xs text-white">
                  {notifications.length}
                </span>
              )}
            </button>

            <button 
              onClick={() => setIsLoginModalOpen(true)}
              className={`w-8 h-8 rounded-full flex items-center justify-center transition-colors ${
                isLightMode 
                  ? 'bg-slate-200/50 hover:bg-slate-200/70' 
                  : 'bg-white/10 hover:bg-white/20'
              }`}
            >
              <User size={18} className={isLightMode ? "text-slate-600" : "text-white/80"} />
            </button>
          </div>
        </header>

        <div className="flex flex-1 overflow-hidden">
          {/* Left Sidebar */}
          <aside className={`w-48 overflow-auto flex-shrink-0 border-r transition-colors duration-200
            ${isLightMode 
              ? 'border-slate-200/50 bg-white/20' 
              : 'border-white/10 bg-black/10'
            }`}>
            <SidebarCategories 
              hierarchicalCategories={hierarchicalCategories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              isLightMode={isLightMode}
            />
          </aside>

          {/* Main Content */}
          <main className="flex-1 p-6 overflow-auto">
            {currentSection === 'christmas' ? (
              <ChristmasGame isLightMode={isLightMode} />
            ) : (
              <section>
                <h2 className={`text-sm font-medium mb-4 ${
                  isLightMode ? 'text-slate-500' : 'text-white/60'
                }`}>
                  {selectedCategory 
                    ? categories.find(c => c._id === selectedCategory)?.name
                    : 'All Apps'}
                </h2>
                <AnimatePresence>
                  {filteredApps.map((app) => (
                    <AnimatedLinkCard 
                      key={app._id} 
                      app={app} 
                      categories={categories}
                      isLightMode={isLightMode}
                      setSelectedCategory={setSelectedCategory}
                    />
                  ))}
                </AnimatePresence>
              </section>
            )}
          </main>
        </div>
      </div>

      {/* Sound toggle button */}
      <button
        onClick={() => setIsMuted(!isMuted)}
        className={`fixed bottom-8 right-20 w-10 h-10 backdrop-blur-md rounded-full 
          flex items-center justify-center shadow-lg transition-colors border z-50
          ${isLightMode 
            ? 'bg-white/40 hover:bg-white/50 border-slate-200/50' 
            : 'bg-white/10 hover:bg-white/20 border-white/20'
          }`}
      >
        {isMuted ? (
          <VolumeX size={18} className={isLightMode ? "text-slate-700" : "text-white"} />
        ) : (
          <Volume2 size={18} className={isLightMode ? "text-slate-700" : "text-white"} />
        )}
      </button>

{/* Theme toggle button */}
<button
        onClick={() => setIsLightMode(!isLightMode)}
        className={`fixed bottom-8 right-8 w-10 h-10 backdrop-blur-md rounded-full 
          flex items-center justify-center shadow-lg transition-colors border z-50
          ${isLightMode 
            ? 'bg-white/40 hover:bg-white/50 border-slate-200/50' 
            : 'bg-white/10 hover:bg-white/20 border-white/20'
          }`}
      >
        {isLightMode ? (
          <Moon size={18} className="text-slate-700" />
        ) : (
          <Sun size={18} className="text-white" />
        )}
      </button>

      {/* Notifications Dropdown */}
      {showNotifications && (
        <div className={`fixed right-8 top-[60px] w-80 rounded-xl shadow-lg py-2 z-50 ${
          isLightMode 
            ? 'bg-white/80 backdrop-blur-md border-slate-200/50' 
            : 'bg-[#1a1c30] border-white/10'
        } border`}>
          <div className={`p-3 border-b ${
            isLightMode ? 'border-slate-200/50' : 'border-white/10'
          }`}>
            <h3 className={`font-medium ${
              isLightMode ? 'text-slate-700' : 'text-white'
            }`}>Recent Apps (24h)</h3>
          </div>
          {notifications.map(notification => (
            <div
              key={notification.id}
              className={`px-4 py-2 ${
                isLightMode 
                  ? 'text-slate-600 hover:bg-slate-100/50' 
                  : 'text-white/80 hover:bg-white/10'
              }`}
            >
              {notification.message}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default App;